<template>
  <div class="theory-container">
    <div class="theory-container-header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>数据中心</el-breadcrumb-item>
        <el-breadcrumb-item to="/trainadmin/datacenter/theoryList">理论榜单</el-breadcrumb-item>
        <el-breadcrumb-item>班级详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-table
            :data="trainingSelect"
            stripe
            height="100%"
            border
            :cell-style="{ color: 'rgba(52, 52, 65, 1)', borderRight: 'unset' }"
            :header-cell-style="{
          color: 'rgba(19, 19, 27, 1)',
          background: '#F3F4FA',
          borderRight: 'unset',
        }"
    >
      <el-table-column  label="序号" width="315" align="center">
        <template slot-scope="scope">
          <div>{{sortNumber(scope.$index)}}</div>
        </template>
      </el-table-column>
      <el-table-column
              prop="student_name"
              label="学生"
              width="315"
              align="center"
      >
      </el-table-column>
      <el-table-column
              prop="times_key"
              label="训练次数"
              width="310"
              align="center"
              sortable
      >
      </el-table-column>
      <el-table-column
              prop="true_percent"
              label="最后训练正确率"
              width="309"
              align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.true_percent }}%</span>
        </template>
      </el-table-column>
      <el-table-column prop="options" label="操作" width="310" align="center">
        <template slot-scope="scope">
          <el-button @click="goToDetail(scope.row)" type="text" size="small"
          >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
            class="pages-center"
            :current-page="listPages.currentPageNum"
            :page-size="listPages.eachPageNum"
            layout="prev, pager, next, jumper"
            :total="listPages.total"
            @current-change="pageCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { showTheoryListData, showStudentTheoryList } from "@/utils/apis";
export default {
  data() {
    return {
      updateTime: "", //数据更新时间
      trainingSelect: [],
      value: "",
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      train_id:this.$route.query.train_id || null
    };
  },
  created() {
    // this.acquisitionTime();
  },
  mounted() {
    this.showStudentTheoryListData();
  },
  methods: {
      sortNumber(index) {
          let no = ((this.listPages.currentPageNum - 1) * this.listPages.eachPageNum) + index + 1;
          return no;
      },
    showStudentTheoryListData() {
      let params = {
        id: this.train_id,
        paging: "1",
        pageSize: this.listPages.eachPageNum,
        page: this.listPages.currentPageNum,
      };
      showStudentTheoryList(params).then((res) => {
        if (res.code === 200) {
          console.log(res.data);
          this.trainingSelect = res.data.list;
          this.listPages.total = res.data.total;
        }
      });
    },
    // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.showStudentTheoryListData();
    },

    // 查看详情
    goToDetail(row) {
      this.$router.push({
        path:'/trainadmin/datacenter/studenttheorydetail',
        query:{
          user_id:row.user_id,
          train_id:row.train_id
        }
      })
    },

    acquisitionTime() {
      let time = new Date();
      this.updateTime = this.dateFormatTwo(time);
    },
    refreshPage() {
      //     更新列表后刷新更新时间
      this.acquisitionTime();
      this.showTheoryList();
    },
    dateFormatTwo: function (time) {
      var date = new Date(time);
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.theory-container {
  ::v-deep .el-button--text {
    color: rgba(17, 34, 216, 1);
  }
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .theory-container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
      color: rgb(0, 0, 0);
      font-weight: bold;
    }
    ::v-deep .el-breadcrumb__item {
      .el-breadcrumb__inner.is-link{
        font-weight: 400;
        color: #606266;
      }
    }
    .training-main {
      .training-title {
        margin-left: 4px;
        font-weight: bold;
      }
    }
  }
  .el-table {
    flex: 1;
    margin-top: 26px;
    ::v-deep .el-table th.el-table__cell {
      background: rgba(245, 245, 251, 1);
    }
  }
  .pages-center {
    margin-top: 20px;
    text-align: center;
  }
}
</style>